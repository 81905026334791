import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import 'typeface-roboto';
import Typography from '@material-ui/core/Typography';

const styles = {
  root: {
    flexGrow: 1,
    position:'relative',
    width: '100%',
  },
};

class Footer extends React.Component {
  constructor(props){
    super(props);
    this.state={
      stage: '',
    }
  }


  render(){
  const { classes } = this.props;

  return (
    <div className={classes.root}>
    <Typography variant="caption" gutterBottom align="right">
      ©2018-20 All Rights Reserved Lepus Technology Limited.
    </Typography>
    </div>
  );
}

}
export default withStyles(styles)(Footer);
