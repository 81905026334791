import React from 'react';
import Header from './header';
import { withStyles } from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from './theme';
import CurrentProject from './current-project';
import AboutUs from './about-us';
import Footer from './footer';

const styles = theme => ({
  root: {
    position: 'absolute',
    left:0,
    top:0,
    width: '100vw',
  },

  mainBody: {
    position: 'relative',
    width: '100%',
  },



});

class Main extends React.Component {
  constructor(props){
    super(props);
    this.state={
      stage: 'CurrentProject',
      name: '',
      tel: '',
      email:'',
      subject: '',
      message:'',
      lastmessagetime:0,

      }
    }

    onStateChange=(val1, val2)=>{
      this.setState({
      [val1]: val2
      },()=>{
        console.log(val1, val2)
      })
    }


  render() {

  const { classes } = this.props;
    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Header
            onStateChange={this.onStateChange}
          />
          <div  className={classes.mainBody}>

          {(this.state.stage==="CurrentProject" && <CurrentProject/>)}
          {(this.state.stage==="AboutUs" && <AboutUs/>)}
          </div>
          <Footer/>

        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles)(Main);
