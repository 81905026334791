import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinkedIn from './img/LinkedIn.png';
import GoogleScholar from './img/Google_Scholar.png';
import LishaTan from './img/Lisha_Tan.png';
import PawelLadosz from './img/Pawel_Ladosz.png';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import GpsFixed from '@material-ui/icons/GpsFixed';
import WifiTethering from '@material-ui/icons/WifiTethering';
import ScatterPlot from '@material-ui/icons/ScatterPlot';
import Cloud from '@material-ui/icons/Cloud';
import Divider from '@material-ui/core/Divider';
import Email from '@material-ui/icons/ContactMail';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import Owl from './img/owl.png'

const styles = theme => ({
  root: {
    minHeight:'88vh',
    position: 'relative',
    width: '100%',
  },
  card: {
    display: 'flex',
    ...theme.mixins.gutters(),
    margin: theme.spacing.unit,
    textAlign: 'left',

  },
  button:{
    marginLeft:'auto',
    marginRight:0,
  },
  banner: {
    backgroundColor: '#E7E7E7',
    textAlign: 'right',
    position: 'relative',
    width: '100%',

  },

    title:{
      textAlign: 'center',
    },


    chip: {
      margin: theme.spacing.unit,

    },
    contactTex:{
    textAlign: 'center',
    marginTop: 20,
    }

});

function AboutUs(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row-reverse"
        justify="flex-start"
        alignItems="center"
        spacing={48}
        >

        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
        className={classes.contactTex}
        >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          >
          <Grid itm>
          <Typography variant="h3"  gutterBottom>
            A Solution Provider Specialising in Big Data and Artificial Intelligence
          </Typography>
          <Chip className={classes.chip} color="secondary" icon={<Cloud />}  label="Big Data"/>
          <Chip className={classes.chip} color="secondary" icon={<GpsFixed />}  label="Optmisation"/>
          <Chip className={classes.chip} color="secondary" icon={<ScatterPlot />}  label="Machine Learning"/>
          <Chip className={classes.chip} color="secondary" icon={<WifiTethering />}  label="UAVs"/>



        </Grid>
        <Grid item>

             <Button
              href="mailto:lisha.tan@lepustechnology.com"
              target="_top"
              >
               <Email/>Email
              </Button>

                <Button
                  href="http://www.linkedin.com/in/lisha-tan"
                  target="_blank"
                >
                  <img
                    src={LinkedIn}
                    style={{height: '75%',
                            width: '75%',}}
                    alt="LinkedIn"
                  />
                  </Button>

                  <Button
                   href="https://docs.google.com/forms/d/e/1FAIpQLSfG9ZfEmlp37bqJ1ahYnEcwYgs5Fpvmq7a90ne0CU_QGjXOUA/viewform?usp=sf_link"
                   target="_blank"
                   >
                      <QuestionAnswer/>Contact Form
                   </Button>

                   <Divider/>


                         <Grid
                           item
                           xs={12}
                           sm={12}
                           md={12}
                           lg={12}
                           xl={8}
                         >
                             <Card className={classes.card}>

                                 <Grid
                                   container
                                   direction="row"
                                   justify={"flex-start"}
                                   alignItems="center"
                                   spacing={24}

                                 >
                                 <Grid
                                   item
                                   xs={9}
                                   sm={9}
                                   md={9}
                                   lg={9}
                                   xl={9}
                                 >
                                     <Typography component="p">
                                       Co-Founder / CEO
                                     </Typography>
                                     <Typography gutterBottom variant="headline" component="h2">
                                       Lisha Tan
                                     </Typography>
                                     <Typography component="p">
                                       7 years industry experience across Finance,
                                       Operations, Business Development, Offering Development,
                                       and IT Project Implementation.
                                     </Typography>
                                     <Typography component="p">
                                       Previously built up and led the Commercial Operations in Paraxel Access Consulting.
                                     </Typography>
                                     <CardActions>
                                       <Button
                                         color="primary"
                                         className={classes.button}
                                         href="http://www.linkedin.com/in/lisha-tan"
                                         target="_blank"
                                       >
                                         <img
                                           src={LinkedIn}
                                           style={ {}}
                                           alt="LinkedIn"
                                         />
                                       </Button>
                                      </CardActions>
                                   </Grid>
                                   <Grid
                                     item
                                     xs={3}
                                     sm={3}
                                     md={3}
                                     lg={3}
                                     xl={3}
                                   >
                                     <img
                                       src={LishaTan}
                                       style={ {width:'100%', height:'100%'}}
                                       alt="Lisha Tan"
                                     />
                                   </Grid>
                                 </Grid>
                             </Card>
                           </Grid>

                           <Grid
                             item
                             xs={12}
                             sm={12}
                             md={12}
                             lg={12}
                             xl={8}
                           >
                             <Card className={classes.card}>

                               <Grid
                                 container
                                 direction="row"
                                 justify="flex-start"
                                 alignItems="center"
                                 spacing={24}
                               >
                               <Grid
                                 item
                                 xs={9}
                                 sm={9}
                                 md={9}
                                 lg={9}
                                 xl={9}
                               >
                                   <Typography component="p" >
                                     Co-Founder / CTO
                                   </Typography>
                                   <Typography gutterBottom variant="headline" component="h2">
                                     Pawel Ladosz
                                   </Typography>
                                   <Typography component="p">
                                     Current Research Assosciate at School of Computer Science in
                                     Loughborough University, UK.
                                   </Typography>
                                   <Typography component="p">
                                     His research interests cover Lifelong learning, Neuroevolution,
                                     Neuromodulation, machine learning, UAVs.
                                   </Typography>
                                   <CardActions>
                                     <Button
                                       color="primary"
                                       className={classes.button}
                                       target="_blank"
                                       href="https://scholar.google.com/citations?user=fSEWVN8AAAAJ&hl=en"
                                     >
                                       <img
                                         src={GoogleScholar}
                                         style={ {}}
                                         alt="Google Scholar"
                                       />
                                     </Button>

</CardActions>
                                 </Grid>
                                 <Grid
                                   item
                                   xs={3}
                                   sm={3}
                                   md={3}
                                   lg={3}
                                   xl={3}
                                 >
                                   <img
                                     src={PawelLadosz}
                                     style={ {width:'100%', height:'100%'}}
                                     alt="Pawel Ladosz"
                                   />
                                 </Grid>
                               </Grid>

                           </Card>
                         </Grid>
                       </Grid>


      </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
      >
        <img
          src={Owl}
          style={{
            objectFit: 'contain',
            height: '100%',
            width: '100%',
          }}
          alt="Contact Us"
        />
      </Grid>
  </Grid>

  </div>
  );
}

AboutUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutUs);
