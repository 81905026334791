import React, { Component } from 'react';
import Main from './main';




class App extends Component {
  render() {

    return (
      <div>

        <Main/>

      </div>
    );
  }
}

export default App;
