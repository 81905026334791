import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SmartPostCode from './img/Smart_Post_Code_Screenshot.png';
import SmartPostCodeAPI from './img/API.png'
import Grid from '@material-ui/core/Grid';
import Project_Background from './img/Lepus_Logo_Icon_Large.png';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Security from '@material-ui/icons/Security';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Commute from '@material-ui/icons/Commute';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import RssFeed from '@material-ui/icons/RssFeed';
import Avatar from '@material-ui/core/Avatar';
import Alarm from '@material-ui/icons/Alarm';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 1,
    minHeight:'88vh',
    textAlign: 'center'
  },
  card: {
     display: 'flex',
     minWidth: 400,
     minHeight: 190,
     margin: theme.spacing.unit

   },
   details: {
     display: 'flex',
     flexDirection: 'column',
   },
   content: {
     flex: '1 0 auto',
   },
   cover: {
     width: 220,
   },
   controls: {
     display: 'flex',
     alignItems: 'center',
     paddingLeft: theme.spacing.unit,
     paddingBottom: theme.spacing.unit,
   },

   title: {
  fontSize: 14,
},
pos: {
  marginTop: theme.spacing.unit,
},
});

function CurrentProject(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
    <img
      src={Project_Background}
      style={ {width: "50vm", height: "50vh"}}
      alt="Project_Background"
    />
    <Typography variant="h3" gutterBottom>
      Next Generation Location Recommendation Engine
    </Typography>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={16}
            >
              <Grid item >
                <Chip className={classes.chip} label="Budget"  avatar={<Avatar><AttachMoney /></Avatar>} />
              </Grid>
              <Grid item>
                <Chip className={classes.chip} label="Travel Time"  avatar={<Avatar><Commute /></Avatar>} />
              </Grid>
              <Grid item>
                <Chip className={classes.chip} label="Local Safety"  avatar={<Avatar><Security /></Avatar>} />
              </Grid>
              <Grid item >
                <Chip className={classes.chip} label="Other User Preferences"  avatar={<Avatar><MoreHoriz /></Avatar>} />
              </Grid>
            </Grid>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={32}
      >
      <Grid
        item
        xs={12}
        sm={10}
        md={5}
        lg={4}
        xl={4}
      >

      <Card className={classes.card}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
              <Typography variant="h5" component="h2" align="left">
                Smart Post Code
              </Typography>
              <Typography color="textSecondary" align="left">
                Neighbourhood recommendation tool for property rental and purchase.
              </Typography>

              <Typography  className={classes.pos}  color="textSecondary" align="left">
                <RssFeed />We are seeking partnership with property portals.
              </Typography>

              </CardContent>
              <div className={classes.controls}>
              <Button
                size="small"
                href="http://www.smartpostcode.co.uk"
                target="_blank"
              >
                Vist Website
              </Button>
              <Button
                size="small"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfG9ZfEmlp37bqJ1ahYnEcwYgs5Fpvmq7a90ne0CU_QGjXOUA/viewform?usp=sf_link"
                target="_blank"

               >
               Contact Us
              </Button>
              </div>
            </div>
            <CardMedia
              className={classes.cover}
              image={SmartPostCode}
            />
          </Card>


       </Grid>
       <Grid
         item
         xs={12}
         sm={10}
         md={5}
         lg={4}
         xl={4}
       >
       <Card className={classes.card}>
             <div className={classes.details}>
               <CardContent className={classes.content}>

               <Typography variant="h5" component="h2" align="left">
                 Smart Post Code API
               </Typography>
               <Typography color="textSecondary" align="left">
               Transforming from information listing to user specific recommendation.
               </Typography>
               <Typography className={classes.pos} color="textSecondary" align="left">
                 <Alarm /> Beta Release: April 2019
               </Typography>
               </CardContent>
               <div className={classes.controls}>
               <Button
                size="small"
                href="https://www.slideshare.net/LishaTan1/smart-post-code-api-128890102"
                target="_blank"
               >
                 Find Out More
               </Button>
               <Button
                size="small"
                href="https://docs.google.com/forms/d/e/1FAIpQLScH_-0u8uVqohSG4rRC36XvTty7ES-Gxf_Q2F9RHMkWpsqJnA/viewform?usp=sf_link"
                target="_blank"
                >

                Register Interest
               </Button>
               </div>
             </div>
             <CardMedia
               className={classes.cover}
               image={SmartPostCodeAPI}
             />
           </Card>
</Grid>
</Grid>


    </div>
  );
}

CurrentProject.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CurrentProject);
