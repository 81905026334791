import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import 'typeface-roboto';

const styles = {
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#FAFAFA',
    position: 'relative',
    top:0,
    left:0,

  },}

class Header extends React.Component {
  constructor(props){
    super(props);
    this.state={
      stage: '',
    }
  }

  onCurrentProject =()=>{
    this.props.onStateChange('stage', 'CurrentProject')
    }

  onAboutUs =()=>{
    this.props.onStateChange('stage', 'AboutUs')
    }


  render(){
  const { classes } = this.props;

  return (
    <div >

        <Grid
          container
          direction="row"
          justify={"flex-end"}
          alignItems="center"
          className={classes.root}
          >

          <Grid item>
                <Button onClick={this.onCurrentProject}>Current Project</Button>
          </Grid>
          <Grid item>
                <Button onClick={this.onAboutUs}>About Us</Button>
          </Grid>
          </Grid>


    </div>
  );
}

}
export default withStyles(styles)(Header);
